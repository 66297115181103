<template>
  <div class="bkCfg">
    <Tabs :value="currrentTab" @on-click="tabClick">
      <TabPane label="485协议板卡" icon="md-cog" name="sbw">
        <keep-alive>
          <sbwcfgcomponent
            :is="tabComponent.sbw"
            :farmOptions="farmOptions"
            :aioGuid="aioGuid"
            :ytjList="ytjList"
          />
        </keep-alive>
      </TabPane>
      <TabPane label="聚英板卡" icon="ios-color-filter" name="jy">
        <keep-alive>
          <jycfgcomponent
            :is="tabComponent.jy"
            :farmOptions="farmOptions"
            :aioGuid="aioGuid"
            :ytjList="ytjList"
          />
        </keep-alive>
      </TabPane>
    </Tabs>
  </div>
</template>
<script>
import JyCfg from "./JyCfg/jyCfg.vue";
import SbwCfg from "./SbwCfg/sbwCfg.vue";
// import LuolaCfg from './LuolaCfg/luolaCfg.vue';
export default {
  name: "bkCfg",
  components: {
    JyCfg,
    SbwCfg,
    // LuolaCfg,
  },
  data() {
    return {
      // 上级页面传入的一体机查询guid
      aioGuid: "",
      // 基地列表
      farmOptions: [],
      // 一体机列表
      ytjList: [],
      currrentTab: "sbw",
      tabComponent: {
        jy: "",
        sbw: SbwCfg,
        // luola: LuolaCfg,
      },
    };
  },
  methods: {
    backDevice() {
      this.$router.push({ name: "sceDevice" });
    },

    tabClick(name) {
      switch (name) {
        case "jy":
          this.tabComponent = {
            ...this.tabComponent,
            jy: JyCfg,
            sbw: "",
            // luola: ''
          };
          break;
        case "sbw":
          this.tabComponent = {
            ...this.tabComponent,
            sbw: SbwCfg,
            jy: "",
            // luola: '',
          };
          break;
        /* case 'luola': 
          this.tabComponent = {
            ...this.tabComponent,
            luola: LuolaCfg,
            jy: '',
            sbw: '',
          };
          break; */
      }
    },
    //获取基地列表
    getFarmList() {
      this.$post(this.$api.BASE_MANAGE.LIST, {
        pageNo: 1,
        pageSize: 99999,
      }).then((resp) => {
        this.farmOptions = resp.list.map((item) => {
          return {
            value: +item.farmId,
            label: item.name,
          };
        });
      });
    },

    getYtjList() {
      return this.$post(this.$api.DEVICES.LIST, {
        pageSize: 99999,
        pageNo: 1,
      }).then((resp) => {
        this.ytjList = resp.list.map((item) => {
          return {
            value: item.guid,
            label: item.name,
          };
        });
        return this.ytjList;
      });
    },
  },
  beforeMount() {
    this.aioGuid = this.$route.query.aioGuid;
    this.getFarmList();
    this.getYtjList().then((resp) => {
      if (!this.aioGuid) {
        this.aioGuid = resp[0] ? resp[0].value : void 0;
      }
    });
  },
  mounted() {},
};
</script>
<style lang="less">
@import "./bkCfg.less";
</style>


